// Этот файл запускается первым

// region Импорт стилей - для корректного отображения страницы загрузки
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'highlight.js/styles/androidstudio.css';
import 'vue2-datepicker/index.css';
import '@/assets/scss/style.scss';
// endregion

import Vue from 'vue';
import auth from '@/services/auth';


Vue.config.productionTip = false;


// Запуск приложения Vue - после загрузки данных о пользователе
auth.start(() => {
    // Запуск приложения Vue
    import(/* webpackChunkName: "mainLaunch" */ './mainLaunch');
});
